import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DialogService, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-merge-account',
  standalone: true,
  imports: [
    FloatLabelModule,
    DialogModule,
    ButtonModule,
    FormsModule,
    InputTextModule,
    CommonModule,
    ReactiveFormsModule,
    DynamicDialogModule,
  ],
  templateUrl: './merge-account-dialog.component.html',
  providers: [DialogService],
})
export class MergeAccountComponent implements OnInit {
  emailForm!: FormGroup;
  title = environment.title;
  constructor(
    private fb: FormBuilder,
    public ref: DynamicDialogRef,
  ){}

  ngOnInit(): void {
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    });
  }

  onSubmit() {
    if (this.emailForm.valid) {
      const email = this.emailForm.value;
      this.ref.close(email);
    }
  }

  closeDialog() {
    this.ref.close();
  }
}
