import { Component, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { ReviewerService } from '../../../../../shared/services/reviewer.service';

@Component({
  selector: 'app-share-profile-data',
  standalone: true,
  imports: [ButtonModule, CheckboxModule, FormsModule],
  templateUrl: './share-profile-data.component.html',
  styleUrl: './share-profile-data.component.scss'
})
export class ShareProfileDataComponent implements OnInit {

  documents: any;

  constructor(private ref: DynamicDialogRef,
    private reviewerService: ReviewerService, 
    public config: DynamicDialogConfig) {}

  ngOnInit(): void {
    this.documents = this.config?.data?.documents;
  }

  submit() {
    let data = {
      ...this.config?.data,
      addResume: true
    }
    this.reviewerService.shareResumeOnAnotherTenant(this.config?.data?.tenantId, data).subscribe((res) => {
      this.ref.close(true);
    })
  }

  cancel(): void {
    this.ref.close();
  }
}
