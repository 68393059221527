<form [formGroup]="emailForm">
  <div>
      <p>Enter a valid {{title}} email address to merge your account</p>
  </div>
    <div class="grid mt-2 mb-3">
      <div class="col-12">
        <p-floatLabel>
          <input type="email" formControlName="email" pInputText />
          <label for="email">Email</label>
        </p-floatLabel>
      </div>
    </div>
    <div class="flex justify-content-start">
      <p-button
        label="Send"
        size="large"
        [disabled]="!emailForm.valid"
        [rounded]="true"
        (onClick)="onSubmit()"
      />
    </div>
  </form>
  