<div class="col-12">
    <div class="border-bottom-1 surface-border">
        <p>
          Hi, you have Resume/Certifications on your another Firm. Would you like to add them in this Firm?
      </p>
      <div class="col-12 lg:col-12 xl:lg:col-12 mb-3 align-items-center">
        <p-button
            class="col-12 lg:col-12 xl:lg:col-12"
            [rounded]="true"
            (click)="cancel()">
            No
        </p-button>
        <p-button
            class="col-12 lg:col-12 xl:lg:col-12"
            (click)="submit()"
            [rounded]="true"
            styleClass="freshgreen-background">
            Yes
        </p-button>
    </div>
    </div>
</div>
  