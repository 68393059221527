<div class="sidebar">
    <div class="main-menu">
        <p-panelMenu [model]="getMenuItemsByRole()">
            <ng-template pTemplate="item" let-item>
                <ng-container *ngIf="item.route">
                    <a [routerLink]="item.route" class="flex align-items-center cursor-pointer wrap-single">
                        <span [class]="item.icon"></span>
                        <span class="ml-3">{{ item.label }}</span>
                        @if(item.label === 'Messages' && messageCount > 0){
                            <p-badge class="flex right" value="{{messageCount}}"  severity="primary"></p-badge>
                            }
                    </a>
                </ng-container>
                <ng-container *ngIf="item.url">
                    <a (click)="selectedTab = item.label" [class.active]="item?.label === selectedTab" [routerLink]="item.url" class="p-menuitem-link pl-4">
                        <span [class]="item.icon"></span>
                        <span>{{ item.label }} </span>
                        
                    </a>
                </ng-container>
            </ng-template>
            <ng-template pTemplate="headercontent" let-item>
                <div (click)="selectedTab = item.label" [class.active]="item?.label === selectedTab" class="flex align-items-center cursor-pointer wrap-single space-adj">
                    <span class="material-symbols-outlined nav-icon">
                       {{item.icon}}
                    </span>
                    <span class="ml-3">{{ item.label }}</span>
                    @if(item.label === 'Messages' && messageCount > 0){
                        <p-badge class="flex right ml-3" value="{{messageCount}}"  severity="primary"></p-badge>
                        }
                </div>
            </ng-template>
        </p-panelMenu>
    </div>
    <div class="additional-menu">
        <p-panelMenu [model]="getAdditionalMenuItemsByRole()">
            <ng-template pTemplate="additionalItems" let-additionalItems>
                <ng-container *ngIf="additionalItems.route">
                    <a [routerLink]="additionalItems.route" class="flex align-items-center cursor-pointer wrap-single">
                        <span [class]="additionalItems.icon"></span>
                        <span class="ml-3">{{ additionalItems.label }}</span>
                    </a>
                </ng-container>
                <ng-container *ngIf="additionalItems.url">
                    <a (click)="selectedTab = additionalItems.label" [class.active]="additionalItems?.label === selectedTab" [routerLink]="additionalItems.url" class="p-menuitem-link pl-4">
                        <span [class]="additionalItems.icon"></span>
                        <span>{{ additionalItems.label }}</span>
                    </a>
                </ng-container>
            </ng-template>

            <ng-template  pTemplate="headercontent" let-additionalItems>
                <div (click)="selectedTab = additionalItems.label" [class.active]="additionalItems?.label === selectedTab" class="flex align-items-center cursor-pointer wrap-single space-adj">
                    @if(additionalItems.label === 'Contact')
                    {
                        <a class="p-menuitem-link" style="color: inherit; text-decoration: none;" href="{{additionalItems.url}}" target="_top">
                            <span class="material-symbols-outlined nav-icon">
                                {{additionalItems.icon}}
                             </span>
                            <span class="ml-3">{{ additionalItems.label}}</span>
                        </a>
                    }
                    @if(additionalItems.label !== 'Contact')
                    {
                        <span class="material-symbols-outlined nav-icon">
                        {{additionalItems.icon}}
                        </span>
                        <span class="ml-3">{{ additionalItems.label }}</span>
                    }
                </div>
            </ng-template>
        </p-panelMenu>
    </div>
</div>
<div class="sidebar-backdrop"></div>